window.onload = () => {

  // donation block
  let $donationSubmit = $('#donation-submit');
  if( $donationSubmit.length ) {
    let $donationTypes = $('input[name="donationType"]');
    let $projectType = $('#projectSelection');
    let $projectTypeOneTimeOnly = $('#projectSelectionOneTimeOnly');
    let oneTimeOnlyUrl = 'https://bibel.profundo.no/';
    let monthlyUrl = 'https://bibel.profundo.no/giver';
    let $donationDescription = $('.donation__form__project__teaser div');

    $projectType.on('change', (e) => {
      let teaserText = JSON.parse($('#projectSelection option:selected').data('description'));
      $donationDescription.html(teaserText);
    });

    $donationSubmit.on('click', (e) => {
      e.preventDefault();
      let url;
      let $donationType = $('input[name="donationType"]:checked');

      if( $donationType && $donationType.val() == '1' ) {
        url = new URL($projectType.val(), oneTimeOnlyUrl);
      } else if( $donationType && $donationType.val() == '2' ) {
        url = new URL(monthlyUrl);
      } else {
        return false;
      }

      window.open( url.href );

    });

    $donationTypes.on('change', (e) => {
      let $this = $(e.currentTarget);
      if( $this.val() == '1' ) {
        $projectType.removeClass('d-none');
        $projectTypeOneTimeOnly.addClass('d-none');
        let teaserText = JSON.parse($('#projectSelection option:selected').data('description'));
        $donationDescription.html(teaserText);
      } else if( $this.val() == '2' ) {
        $projectType.addClass('d-none');
        $projectTypeOneTimeOnly.removeClass('d-none');
        $donationDescription.html('Gjennom denne givertjenesten støtter du hver måned et konkret land som vi har lovet støtte til.');
      }
    });

  }
  // end donation block

  // read the bible block
  let $bibleLangSelect = $('#bible-language');
  if( $bibleLangSelect.length ) {

    let $selectedChapter = $('.block__readTheBible #dropdownMenuButton');
    let $dropdownMenu = $selectedChapter.siblings('.dropdown-menu');

    // populate languages
    for(let index in bibleChapters.languages) {
      let lang = bibleChapters.languages[index];
      for( let index2 in lang.versions ) {
        let $option = $('<option>');
        $option.val( lang.versions[index2].prefix );
        $option.html( lang.name + ' - ' + lang.versions[index2].name );
        $bibleLangSelect.append($option);
      }
    }

    // populate Old Testament content
    let $gtAccordion = $('#accordionGT');
    let html = '';
    for(let index in bibleChapters.content[0]) {
      let book = bibleChapters.content[0][index];

      html += `
          <div class="card">
              <div class="card-header" id="GTheading${index}">
              <h5 class="mb-0">
                  <button class="collapsed btn btn-link" type="button" data-toggle="collapse" data-target="#GTcollapse${index}" aria-expanded="false" aria-controls="GTcollapse${index}">
                  `;
      if (Array.isArray(book.name)) {
        for (let i = 0; i < book.name.length; i++) {
          html += `<span data-lang="${i}">${book.name[i]}</span>`;
        }
      } else {
        html += book.name;
      }
      html += `
                  </button>
              </h5>
              </div>

              <div id="GTcollapse${index}" class="collapse" aria-labelledby="GTheading${index}" data-parent="#accordionGT">
                  <div class="card-body">
                      <em>Velg kapittel</em>`;
                      for(let i = 1; i <= book.chapterCount; i++) {
                        html += `<a href="" class="bibleChapterLink" data-prefix="${book.prefix}" data-chapter="${i}">${i}</a>`;
                      }
        html += `
                  </div>
              </div>
          </div>
      `;
    }
    $gtAccordion.append(html);

    // populate New Testament content
    let $ntAccordion = $('#accordionNT');
    html = '';
    for(let index in bibleChapters.content[1]) {
      let book = bibleChapters.content[1][index];

      html += `
          <div class="card">
              <div class="card-header" id="NTheading${index}">
              <h5 class="mb-0">
                  <button class="collapsed btn btn-link" type="button" data-toggle="collapse" data-target="#NTcollapse${index}" aria-expanded="false" aria-controls="NTcollapse${index}">
                  `;
      if (Array.isArray(book.name)) {
        for (let i = 0; i < book.name.length; i++) {
          html += `<span data-lang="${i}">${book.name[i]}</span>`;
        }
      } else {
        html += book.name;
      }
      html += `
                  </button>
              </h5>
              </div>

              <div id="NTcollapse${index}" class="collapse" aria-labelledby="NTheading${index}" data-parent="#accordionNT">
                  <div class="card-body">
                      <em>Velg kapittel</em>`;
      for(let i = 1; i <= book.chapterCount; i++) {
        html += `<a href="" class="bibleChapterLink" data-prefix="${book.prefix}" data-chapter="${i}">${i}</a>`;
      }
      html += `
                  </div>
              </div>
          </div>
      `;
    }
    $ntAccordion.append(html);

    let $btn = $('#readTheBible');
    // button event handler for navigation
    $btn.on('click', (e) => {
      let url = $btn.attr('data-url');
      if( url ) {
        window.open(url);
      }
    });

    // chapter click event handler
    $('a.bibleChapterLink').on('click', (e) => {

      e.preventDefault();
      let $link = $(e.currentTarget);

      // create redirect link
      let prefix = $link.attr('data-prefix');
      let chapter = $link.attr('data-chapter');
      let lang = $bibleLangSelect.val();
      let path = lang + '/' + prefix + '.' + chapter;
      let url = `${bibleChapters.baseUrl}?slang=${lang}&book=${prefix}&chapter=${chapter}`;
      $btn.attr('data-chapter', prefix + '.' + chapter);
      $btn.attr('data-url', url);

      // fill in dropdown text
      let $evangelistCard = $link.closest('.card');
      let $evangelistCollapse = $evangelistCard.find('.collapse');
      let $evangelistHeaderButton = $evangelistCard.find('.card-header button');
      let evangelist = $evangelistHeaderButton.text().trim();
      $selectedChapter.html(evangelist + ' - ' + chapter);

      // reset dropdown content
      $dropdownMenu.scrollTop(0);
      $dropdownMenu.removeClass('show');
      $evangelistHeaderButton.attr('aria-expanded', false);
      $evangelistCollapse.removeClass('show');

    });

    $bibleLangSelect.on('change', (e) => {
      let $btn = $('#readTheBible');
      let chapter = $btn.attr('data-chapter');
      let book = $btn.attr('data-prefix');
      if( chapter && book ) {
        let lang = $(e.currentTarget).val();
        let url = `${bibleChapters.baseUrl}?slang=${lang}&book=${book}&chapter=${chapter}`;
        $btn.attr('data-url', url);
      }
      const langCode = $(e.currentTarget).val().startsWith('bokmal') ? 0 : 1;
      $selectedChapter.attr('data-selected-lang', langCode);
    }).change();

  }
  // end read the bible block

  /* filtering employees */
  if( $('.block__employeeList').length ) {
    function filter(htmlClass) {
      const $listContainer = $('.block__employeeList .row.list');
      $listContainer.find('.block__item').each((index, el) => {
        if( htmlClass.length ) {
          if( $(el).hasClass( htmlClass ) ) {
            $(el).show();
          } else {
            $(el).hide();
          }
        } else {
          $(el).show();
        }
      });
    }

    $('.list__categories').on( 'click', 'a', function() {
      $('.list__categories a').each((index, el) => {
        $(el).removeClass('selected');
      });
      $( this ).addClass('selected');
      let filterValue = $( this ).attr('data-filter');
      filter(filterValue);
    });

  }
  /* end filtering employees */
};

// bible data
const bibleChapters = {
  baseUrl: '/nettbibelen',
  languages: {
    0 : {
      name : 'Norsk, bokmål',
      versions : {
        1 : {
          name : 'Bibelen 2024 Bokmål',
          prefix : 'bokmal24'
        }, 2 : {
          name : 'Bibelen 2011 Bokmål',
          prefix : 'bokmal11'
        }, 3 : {
          name : 'Bibelen 1978/85 Bokmål',
          prefix : 'bokmal78'
        }, 4 : {
          name : 'Bibelen 1930 bokmål',
          prefix : 'bokmal30'
        }
      }
    }, 1 : {
      name : 'Norsk, nynorsk',
      versions : {
        1 : {
          name : 'Bibelen 2024 Nynorsk',
          prefix : 'nynorsk11'
        }, 2 : {
          name : 'Bibelen 2011 Nynorsk',
          prefix : 'nynorsk11'
        }, 3 : {
          name : 'Bibelen 1978/85 Nynorsk',
          prefix : 'nynorsk78'
        }, 4 : {
          name : 'Bibelen 1938 Nynorsk',
          prefix : 'nynorsk38'
        }
      }
    }, 2 : {
      name : 'Nordsamisk',
      versions : {
        0 : {
          name : 'Davvisámegiel Biibbal 2019',
          prefix : 'nordsamisk'
        }
      }
    }, 3 : {
      name : 'Lulesamisk',
      versions : {
        0 : {
          name : 'Lulesamisk',
          prefix : 'lulesamisk'
        }
      }
    }, 4 : {
      name : 'Sørsamisk',
      versions : {
        0 : {
          name : 'Sørsamisk',
          prefix : 'sorsamisk'
        }
      }
    }
  },
  content: {
    0 : {
      0 :{
        name : '1. Mosebok',
        prefix : 'GEN',
        chapterCount : 50,
      }, 1 :{
        name : '2. Mosebok',
        prefix : 'EXO',
        chapterCount : 50,
      }, 2 : {
        name : '3. Mosebok',
        prefix : 'LEV',
        chapterCount : 27
      }, 3 : {
        name :'4. Mosebok',
        prefix : 'NUM',
        chapterCount : 36
      }, 4 : {
        name : '5. Mosebok',
        prefix : 'DEU',
        chapterCount : 34
      }, 5 : {
        name : 'Josva',
        prefix : 'JOS',
        chapterCount : 24
      }, 6 : {
        name : ['Dommerne', 'Dommarane'],
        prefix : 'JDG',
        chapterCount : 21
      }, 7 : {
        name : 'Rut',
        prefix : 'RUT',
        chapterCount : 4
      }, 8 : {
        name : '1. Samuelsbok',
        prefix : '1SA',
        chapterCount : 31
      }, 9 : {
        name : '2. Samuelsbok',
        prefix : '2SA',
        chapterCount : 24
      }, 10 : {
        name : '1. Kongebok',
        prefix : '1KI',
        chapterCount : 22
      }, 11 : {
        name : '2. Kongebok',
        prefix : '2KI',
        chapterCount : 25
      }, 12 : {
        name : '1. Krønikebok',
        prefix : '1CH',
        chapterCount : 29
      }, 13 : {
        name : '2. Krønikebok',
        prefix : '2CH',
        chapterCount : 36
      }, 14 : {
        name : 'Esra',
        prefix : 'EZR',
        chapterCount : 10
      }, 15 : {
        name : 'Nehemja',
        prefix : 'NEH',
        chapterCount : 13
      }, 16 : {
        name : 'Ester',
        prefix : 'EST',
        chapterCount : 10
      }, 17 : {
        name : 'Job',
        prefix : 'JOB',
        chapterCount : 42
      }, 18 : {
        name : ['Salmene', 'Salmane'],
        prefix : 'PSA',
        chapterCount : 150
      }, 19 : {
        name : ['Ordspråkene', 'Ordtaka'],
        prefix : 'PRO',
        chapterCount : 31
      }, 20 : {
        name : ['Forkynneren', 'Forkynnaren'],
        prefix : 'ECC',
        chapterCount : 12
      }, 21 : {
        name : ['Høysangen', 'Høgsongen'],
        prefix : 'SNG',
        chapterCount : 8
      }, 22 : {
        name : 'Jesaja',
        prefix : 'ISA',
        chapterCount : 66
      }, 23 : {
        name : 'Jeremia',
        prefix : 'JER',
        chapterCount : 52
      }, 24 : {
        name : ['Klagesangene', 'Klagesongane'],
        prefix : 'LAM',
        chapterCount : 5
      }, 25 : {
        name : 'Esekiel',
        prefix : 'EZK',
        chapterCount : 48
      }, 26 : {
        name : 'Daniel',
        prefix : 'DAN',
        chapterCount : 12
      }, 27 : {
        name : 'Hosea',
        prefix : 'HOS',
        chapterCount : 14
      }, 28 : {
        name : 'Joel',
        prefix : 'JOL',
        chapterCount : 3
      }, 29 : {
        name : 'Amos',
        prefix : 'AMO',
        chapterCount : 9
      }, 30 : {
        name : 'Obadja',
        prefix : 'OBA',
        chapterCount : 1
      }, 31 : {
        name : 'Jona',
        prefix : 'JON',
        chapterCount : 4
      }, 32 : {
        name : 'Mika',
        prefix : 'MIC',
        chapterCount : 7
      }, 33 : {
        name : 'Nahum',
        prefix : 'NAM',
        chapterCount : 3
      }, 34 : {
        name : 'Habakukk',
        prefix : 'HAB',
        chapterCount : 3
      }, 35 : {
        name : 'Sefanja',
        prefix : 'ZEP',
        chapterCount : 3
      }, 36 : {
        name : 'Haggai',
        prefix : 'HAG',
        chapterCount : 2
      }, 37 :{
        name : 'Sakarja',
        prefix : 'ZEC',
        chapterCount : 14
      }, 38 : {
        name : 'Malaki',
        prefix : 'MAL',
        chapterCount : 4
      }
    }, 1 : {
      0 : {
        name : 'Matteus',
        prefix : 'MAT',
        chapterCount : 28
      }, 1 : {
        name : 'Markus',
        prefix : 'MRK',
        chapterCount : 16
      }, 2 : {
        name : 'Lukas',
        prefix : 'LUK',
        chapterCount : 24
      }, 3 : {
        name : 'Johannes',
        prefix : 'JHN',
        chapterCount : 21
      }, 4 : {
        name : ['Apostelgjerningene', 'Apostelgjerningane'],
        prefix : 'ACT',
        chapterCount : 28
      }, 5 : {
        name : ['Romerne', 'Romarane'],
        prefix : 'ROM',
        chapterCount : 16
      }, 6 : {
        name : ['1. Korinter', '1. Korintar'],
        prefix : '1CO',
        chapterCount : 16
      }, 7 : {
        name : ['2. Korinter', '2. Korintar'],
        prefix : '2CO',
        chapterCount : 13
      }, 8 : {
        name : ['Galaterne', 'Galatarane'],
        prefix : 'GAL',
        chapterCount : 6
      }, 9 : {
        name : ['Efeserne', 'Efesarane'],
        prefix : 'EPH',
        chapterCount : 6
      }, 10 : {
        name : ['Filipperne', 'Filipparane'],
        prefix : 'PHP',
        chapterCount : 4
      }, 11 : {
        name : ['Kolosserne', 'Kolossarane'],
        prefix : 'COL',
        chapterCount : 4
      }, 12 : {
        name : ['1. Tessaloniker', '1. Tessalonikar'],
        prefix : '1TH',
        chapterCount : 5
      }, 13 : {
        name : ['2. Tessaloniker', '2. Tessalonikar'],
        prefix : '2TH',
        chapterCount : 3
      }, 14 : {
        name : '1. Timoteus',
        prefix : '1TI',
        chapterCount : 6
      }, 15 : {
        name : '2. Timoteus',
        prefix : '2TI',
        chapterCount : 4
      }, 16 : {
        name : 'Titus',
        prefix : 'TIT',
        chapterCount : 3
      }, 17 : {
        name : 'Filemon',
        prefix : 'PHM',
        chapterCount : 1
      }, 18 : {
        name : ['Hebreerne', 'Hebrearane'],
        prefix : 'HEB',
        chapterCount : 13
      }, 19 : {
        name : 'Jakob',
        prefix : 'JAS',
        chapterCount : 5
      }, 20 : {
        name : '1. Peter',
        prefix : '1PE',
        chapterCount : 5
      }, 21 : {
        name : '2. Peter',
        prefix : '2PE',
        chapterCount : 3
      }, 22 : {
        name : '1. Johannes',
        prefix : '1JN',
        chapterCount : 5
      }, 23 : {
        name : '2. Johannes',
        prefix : '2JN',
        chapterCount : 1
      }, 24 : {
        name : '3. Johannes',
        prefix : '3JN',
        chapterCount : 1
      }, 25 : {
        name : 'Judas',
        prefix : 'JUD',
        chapterCount : 1
      }, 26 : {
        name : ['Åpenbaringen', 'Openberringa'],
        prefix : 'REV',
        chapterCount : 22
      }
    }
  }
};
